import fetch from "~/utils/fetch";
import {CartItem, CostValues} from "~/cart/types";
import {ClientTenant} from "~/tenant/types";

export default {
  create: (slug: ClientTenant["slug"], items: CartItem[], orderId: string, mapPrices: CostValues, country: string, tier: string) =>
    fetch("POST", `/api/payment`, {slug, items, orderId, mapPrices, country, tier}).then(({url}) => url),
  getDaysAccreditation: (id: ClientTenant["id"]) =>
    fetch("GET", `/api/days-accreditation/${id}`).then(({json}) => json)
};
